/* General Container Styles */
.socials__container {
    background-color: #303d5b;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .socials__section {
    width: 60vw;
    display: flex;
    justify-content: center;
  }
  
  /* Notebook Image Section */
  .socials__image-container {
    width: 30%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .socials__notebook {
    width: 100%;
  }
  
  /* Content Section */
  .socials__content {
    width: 60vw;
    display: grid;
    grid-template-columns: 4vw auto;
    grid-template-rows: 10vh repeat(7, 5vh);
    background-color: #9ebbff;
    border-radius: 30px;
  }
  
  /* Notebook Holes */
  .socials__holes {
    margin-top: 20px;
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .socials__hole {
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    background-color: #061d4f;
  }
  
  /* Horizontal and Vertical Lines */
  .socials__line {
    border-top: 2px solid #0d4edb;
    width: 100%;
  }
  
  .socials__vertical-line {
    grid-area: 4 / 1 / 9 / 1;
    border-right: 2px solid #0d4edb;
    width: 100%;
    height: 100%;
  }
  
  /* Specific Lines */
  .socials__line--one {
    grid-area: 2 / 1 / 3 / 3;
  }
  
  .socials__line--two {
    grid-area: 3 / 1 / 4 / 3;
  }
  
  .socials__line--three,
  .socials__line--four {
    grid-area: 4 / 1 / 5 / 3;
  }
  
  .socials__line--six {
    grid-area: 5 / 1 / 6 / 3;
  }
  
  .socials__line--seven {
    grid-area: 6 / 1 / 7 / 3;
  }
  
  .socials__line--eight {
    grid-area: 7 / 1 / 8 / 3;
  }
  
  .socials__line--nine {
    grid-area: 8 / 1 / 9 / 3;
  }
  
  /* Title and Date */
  .socials__title {
    grid-area: 2 / 1 / 3 / 3;
  }
  
  .socials__date {
    grid-area: 3 / 1 / 4 / 3;
  }
  
  .socials__title-text {
    font-size: 1vw;
    color: #061d4f;
    margin-left: 10px;
  }
  
  /* Social Links */
  .socials__link {
    margin-top: 1.5vh;
  }
  
  .socials__link--linkedin {
    grid-area: 4 / 2 / 5 / 3;
  }
  
  .socials__link--github {
    grid-area: 5 / 2 / 6 / 3;
  }
  
  .socials__link-text {
    font-size: 2vw;
    margin: 0;
    color: #061d4f;
    font-weight: 600;
    margin-left: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .socials__section {
        width: 80vw;
    }
    .socials__content {
 
      width:100vw;
    }
  
    .socials__image-container {
      display: none;
    }
  
    .socials__hole {
      width: 20px;
      height: 20px;
    }
  
    .socials__title-text {
      font-size: 2.5vw;
    }
  
    .socials__link-text {
      font-size: 4vw;
    }
  }
  