
.page-header{
    background-color: #3D3D3D;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 6vh;
    display: flex;
    text-align: center;
  
 
}
.page-header .round-btn{
    width: 15px;
    height: 15px;
    border-radius: 60%;
    margin-top: 20px;
    margin-left: 10px;
}

.page-header p{
    color: white;
    width: 55dvw;
    margin-right: 40px;

}
.red{
    background-color: #FF5E57;
}
.yellow{
    background-color: #FEBC2E;
}
.green{
    background-color: #29C840;
}
.page-body{
    display: flex;

    background-color: #2C2C2C;
    border-bottom-left-radius:20px;
    border-bottom-right-radius:20px;

}

.side-menu{
    width: 25%;
    border-right: 2px solid #5B5B5B ;
}

.continer{
    width: 75%;
    margin:  0 20px;
}

.inbox-holder{
    background-color: #363636;
    width: 90%;
    height: 6%;
    margin: 10% 2% 2% 2%;
    justify-self: center;
}

/*  negin case stdy lable */
.lable-section{
    display: flex;
    align-items: center ;


}


.lable-section-small{
    margin:  2% 5% ;
}

.lable-section-big{
    height: 10vh;
}

.lable-section .lable{
    color: white;
}

.lable-section .small-lable{
   margin-left: 10px;
   font-size: 15px; 
}

.lable-section .big-lable{
    margin-left: 10px;
  
    font-size:30px;   
 }

 .menu-item{
    background-color: #4A5878;
    display: flex;
    padding: 10px 2px;
   
    
 }
 .menu-icon img{
    width: 25px;
    margin-left: 30%;
 }
 .menu-lable{
    color: white;
    margin-left: 20px;
  
 }

 .casestudy-list{
    display: flex;
    gap: 15px;
    width:45vw;
    overflow-x: auto; /* Enables horizontal scroll */

    
 }



@media (max-width: 768px) {
    .casestudy-list{
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: 40vh;
     }
     .casestudy-list{
        width: 80vw;
        border: "1px solid yellow";
     }

     .lable-section-big{
        padding: 15px 0; 
      }

     .lable-section .big-lable{
        margin-left: 10px;
        font-size:20px;   
     }

     .lable-section .small-lable{
        display: none;
     }

     .menu-lable{
        display: none;
     }
     .menu-item{

        justify-content: center;
        margin: 0;
     }
     .menu-icon img{
        
        margin-left: 0;
     }
   .lable-section-small{
    justify-content: center;
    margin: 10px 0;
}
    .inbox-holder{
        width: 70%;
    }
    .page-body{
  

    }
    .page-header{
       
        height: 7vh;
 
     
       
    }
    .page-header .round-btn{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-top: 10px;
        margin-left: 10px;
    }
    
}