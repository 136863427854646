
.terminal{
    background-color: #141414;
    padding: 2%;
    overflow-x: scroll; 
    border-bottom-left-radius:20px;
    border-bottom-right-radius:20px;
  }
   
  .second-line{
    display: flex;
    
  }
  .third-line{
    display: flex;
  }
  
  .terminal p{ 
    font-family: "Lexend", sans-serif;
     color: #FCEA5F;
     font-size: 1.3vw;
     font-weight: 300;  
     margin-bottom: 0;
  }
  .typing-1{
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    border-right: 5px solid  #FCEA5F;
    animation: type1 3s steps(30) forwards;
    animation-delay: 0.5s;
    margin-left: 10px;
  }
  @keyframes type1 {
    0%{ 
      opacity: 1; 
      width: 0ch }
    100% { 
       opacity: 1; 
       width: 22ch;
       border: none;
      
    }
  }
  
  .show-p-tag {
    animation: showMe 0s 3s forwards;
    opacity: 0; 
  }
  
  @keyframes showMe {
    to   { opacity: 1; }
    
  }
  
  .typing-2{
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    margin-left: 10px;
    border-right: 5px solid #FCEA5F;
    animation: type2 0.5s steps(2) forwards ;
    animation-delay: 3s;
    
  }
  @keyframes type2 {
   from{ 
      opacity: 1; 
      width: 0ch
    }
    to { 
      opacity: 1; 
      width: 2ch ;
      border: none;
    }
  }
  
  .show-project-list {
    animation: showMe 0s 4s forwards;
    opacity: 0; 
    margin-top: 2%;
  
  }

  
  
  
  .show-project-list a p:hover {
    color: #989369
  }

  .show-project-list a p {
    text-decoration: underline;
  }
  
  
  @media (max-width: 768px) {
    .terminal p{ 
  
        font-size:2.5vw;
  
     }
  }
  
  