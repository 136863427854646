
.tag-container {
  background: #6e6829;
  border-radius: 15px;
  padding: 10px;
  width: 30%;
  border: "1px solid red";
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.tag-container:hover {
  animation: shake 0.5s ease-in-out;
}

.tag-title {
  color: white;
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 0;
}

.tag-item {
  background-color: #c8c28a;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.tag-item:hover {
  background-color: #b7a97a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}





.card-container {
    width: 30%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: auto auto; /* Automatic rows */
    gap: 10px; /* Space between cards */
    background-color: #6e6829; /* Background color of the container */
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
  }
  
  .card {
    width: 5vw;
    height: 5vh;
    background-color: #c8c28a; /* Light background color for cards */
    padding: 10px;
    border-radius: 8px;
    color: #000;
    display: flex;
    justify-content: center;
    font-weight: 500;
    align-items: center;
    font-size: 12px;
  }
  
  .footer {
    grid-column: span 2; /* Footer spans across both columns */

    color: white;
    font-size: 20px;
    text-align: center;
  
    margin: 0;
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }

  .card-container:hover {
    animation: shake 0.5s ease-in-out;
  }

  @media (max-width: 768px) {
    .card-container {
        width: 60%;
        padding: 15px;
     }

     .card {
        width: 12vw;
        height: 7vh;
        font-size: 12px;
       
      }
   
    
} 
  