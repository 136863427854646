.laptop-main-div{
    background-color: #7E7429;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.laptop-section{
    display: flex;
    justify-content: center;
}

.laptop-image-continer {
    width:20%;
    margin-right: 5%;
    justify-self: center; 
    align-self: center;   
}

.laptop-image-continer img {
    width: 100%;       
}

.page-continer{
    justify-self: center; 
    align-self: center; 
    position: relative;

}


.page{  
    height: 80vh;
    border-radius: 20px;

} 

.page-body{
height: 100%;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }

 
.menu-btn-continer{
    padding: 15px;
    background-color: #7C7C7C;
    border-radius: 15px;
    position: absolute;
    justify-self: center ;
    align-items: center;
    bottom: 20px;
    left:10% ;
    right:10% ;  
    display: flex;

}
.hint{
    font-size: 15px;
    color: rgb(157, 157, 157);
    position: absolute; 
    border-radius: 15px;
    position: absolute;
    justify-self: center ;
    align-items: center;
    bottom: 20px;
    left:10% ;
    right:10% ;  
    bottom: 17%;
    display: flex;
}



.menu-img-btn{
    width: 50px;
}
.menu-img-btn:hover {
    animation: shake 0.5s ease-in-out;
  }
.img-space {
   margin-right: 10px;
  }

.selection-indicator{
    justify-self: center ;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ebd558;

}
.selection-indicator-empty{
    justify-self: center ;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;

}



@media (max-width: 768px) {

    .laptop-main-div{
        height: 90vh;
    }
 
    .laptop-image-continer {
       display: none;
      
    }
    
    .page{
        height: 80vh;
    }

    .hint{
        bottom: 15%;
    }

    .menu-btn-continer{
        bottom: -10px;
    }
   
    
}