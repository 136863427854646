.table-continer{
    background-color: #543322;
   height: 100vh;


   display: flex;
   justify-content: center;
   align-items: center;
}



.grid-container {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: 30% 60% 20%;
    padding: 5% 10% 10% 10%;
 
}
  
.grid-item-title{
    grid-area: 1/1/1/4;
    text-align: center;
}
.grid-item-title p{

    font-size: 2vw;
    color: #fff; 
    font-weight: 200;
}
.mainpage-img-btn:hover {
    animation: shake 0.5s;
}


.grid-item-img{
/*  border: 1px solid yellow;    */
    text-align: center;
}

.grid-item-mirror{
    grid-area: 2/1/2/2;
    justify-self: center; /* Horizontal centering */
     align-self: center;   /* Vertical centering */
}


.grid-item-laptop{
    grid-area: 2/2/2/2;
}

.grid-item-notebook{
    grid-area: 2/3/2/4;
    margin-top: 15%;
    justify-self: center; /* Horizontal centering */
    align-self: center;   /* Vertical centering */
   
}

.grid-item-lable{
 /*   border: 1px solid orange; */
    text-align: center;
    padding: 0 10%;
}
.grid-item-mirror-lable {
    grid-area: 3/1/3/2;
}

.grid-item-laptop-lable  {
    grid-area: 3/2/3/2;

}

.grid-item-notebook-lable{
    grid-area: 3/3/3/4;
}

.grid-item-lable p{
    font-size: 1.5vw;
    font-weight: 300;
} 
.mirror-grid-text-color {
    color:#9FE9DF;
  }

  .laptop-grid-text-color {
    color:#EFE415;
  }

  .notebook-grid-text-color {
    color:#A1D4FB;
  }

  /* Define the shake animation */
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }

  .shake-on-hover:hover {
    animation: shake 0.5s ease-in-out;
  }

.notebook-image{
    width: 35%;
}
.laptop-image{
    width: 70%;
}
.mirror-image{
    width: 70%;
}




  @media (max-width: 768px) {

    .table-continer{
        height: 90vh;
    }


    .grid-container {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 20% 30% 30% 30%;
        background-color: #543322;
        padding-top: 20%;
  
        place-items: center;
   
    }

    .grid-item-img{

            text-align: center;
        }
        
        .grid-item-mirror{
            grid-area: 2/1/2/2;
            padding-top: 0;
      
         
        }
        .grid-item-mirror-lable {
            grid-area: 2/2/3/3;
          
        
        }
        
        .grid-item-laptop{
            grid-area: 3/2/3/3;
           
            
        }

        .grid-item-laptop-lable  {
            
            grid-area: 3/1/3/2;
         
        
        }
        
        .grid-item-notebook{
            grid-area: 4/1/4/2;
            margin-bottom: 15%; 
         
     
       
        }
        .grid-item-notebook-lable{
            grid-area: 4/2/4/3;
           
           
        }
        
        .grid-item-lable{
   
            text-align: center;

        }

        .grid-item-lable p{
            font-size: 4vw;
        } 

        .grid-item-title p {
            font-size: 3.5vw;
        }

        .notebook-image{
            width: 40%;
        }
        .laptop-image{
            width: 80%;
        }
        .mirror-image{
            width: 80%;
        }
      
      
      
    
  }