
/* General Container Styles */
.about-me__container {
    background-color: #79928E;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  /* Main Section */
  .about-me__section {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
  }
  
  /* Left Section: Mirror Image */
  .about-me__image-container {
    width: 20%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about-me__mirror {
    width: 100%;
  }
  
  /* Right Section: Content and Stickers */
  .about-me__content {
    width: 60%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  /* Stickers */
  .about-me__sticker {
    position: absolute;
    width: 20vw;
    height: 10vh;
  }
  
  .about-me__sticker--regular {
    top: -30px;
    right: 5px;
  }
  
  .about-me__sticker--pattern {
    top: 2%;
    right: -40px;
  }
  
  /* Content Box */
  .about-me__text-content {
    background-color: #FFFFFF;
    border: 20px solid #B8CBC8;
    border-radius: 30px;
    padding: 2%;
  }
  
  .about-me__text-content p {
    color: #09332D;
  }
  
  /* Title and Body Styles */
  .about-me__title {
    font-size: 2.5vw;
    font-weight: 300;
  }
  
  .about-me__description {
    font-family: "Lexend", sans-serif;
    font-size: 1.3vw;
    font-weight: 300;
  }
  
  /* Responsive Styles for Tablets and Smaller Screens */
  @media (max-width: 768px) {
    .about-me__container{
      height: 90vh;
  }
   
    /* Hide Left Section */
    .about-me__image-container {
      display: none;
    }
  
    /* Expand Content Section */
    .about-me__content {
      width: 90%;
      margin-bottom: 3vh;
    }

    
  
    /* Adjust Title and Body Font Sizes */
    .about-me__title {
      font-size: 4vw;
    }
  
    .about-me__description {
      font-size: 2vw;
    }
  
    /* Adjust Stickers */
    .about-me__sticker {
      width: 28vw;
      height: 5vh;
    }
  
    .about-me__sticker--regular {
      top: 10px;
      right: 5px;
    }
  
    .about-me__sticker--pattern {
      top: 40px;
      right: -10px;
    }
  }
  

