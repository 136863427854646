
@import url("https://fonts.googleapis.com/css2?family=ADLaM+Display&family=Indie+Flower&family=Lexend:wght@100..900&family=Patrick+Hand&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=ADLaM+Display&family=Indie+Flower&family=Patrick+Hand&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap'); */


body {
  margin: 0;
 /*  font-family: "Sour Gummy", sans-serif; */
 font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
